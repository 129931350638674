.formName {
  width: auto;
  height: 31px;
  font-size: 24px;
  left: 52px;
  color: #2d2d2d;
  position: relative;
  top: 33px;
}

.mehlogin {
  background: url("../../assets/images/logo.png") no-repeat;
  height: 100%;
}

.labeling {
  position: relative;
  font-size: 14px;
  color: #858585;
  top: 8px;
}

.form {
  width: 320px;
  height: 350px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.71);
  background-color: #ffffff;
  top: 362px;
  left: 804px;
  float: right;
  margin-right: 10%;
}

.formInput {
  position: relative;
  height: 45px;
  margin: 37px 52px;
  top: 30px;
}

.loginFormButton {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  background-color: #1890ff;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.submit {
  margin: 2px 52px;
  position: relative;
  top: 50px;
}

.footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.fpTitle,
.fpSubTitle {
  font-size: 20px;
  color: #313232;
  font-family: "MyriadProSemiBold" !important;
}

.fpSubTitle {
  display: flex;
  align-items: center;
  font-size: 16px;
}
