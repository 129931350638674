.footer {
  /* position: fixed; */
  /* left: 0; */
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  max-height: 42px;
  padding: 10px;
  color: #292b2c;
  /* border-top: 1px solid #cfcfcf; */
  background-color: #fff;
  bottom: 0;
}

.footer ul {
  margin: 0;
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer li {
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  padding: 0px 12px;
  color: #292b2c;
}

.footer li a {
  color: #505658;
}

.footer li a:hover {
  color: #1a73e8;
}
