.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 50px;
  margin: 5px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-layout-background-bg {
  background-image: url(../../assets/images/backgroundpng.svg), url(../../assets/images/backgroundimg.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
  background-color: #f7f7f7;
}

.site-layout header,
.site-layout .ant-menu:first-child,
.ant-menu:nth-child(2) {
  background: #fff;
}
.site-layout-content {
  height: calc(100vh - 185px);
}
.layout .ant-layout-footer {
  padding: 0 24px 5px 24px;
}
