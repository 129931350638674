.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.site-layout-content .ant-tabs-nav {
  margin-bottom: 0;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.chart-class {
  padding: 10px 0;
}

#ac-chart-container {
  width: calc(100% - 75px) !important;
}
.vertical-slider {
  display: flex;
  align-items: center;
  width: 70px;
  padding-top: 5%;
  background-color: #0a6fb8;
}

.horizontal-slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  padding: 5px 20px;
  background-color: #0a6fb8;
}
.chart-div {
  border: 1px solid #e5e5e5;
  margin: 10px 0;
}
.chart-div .horizontal-slider .ant-slider-mark-text {
  min-width: 100px;
  font-weight: 500;
}
.table-numeric {
  display: flex;
  justify-content: space-evenly;
}

.table-numeric span:first-child {
  font-size: 18px;
  font-weight: 700;
}

.ant-table-container table > thead th {
  font-weight: 900;
  padding-top: 7px;
  padding-bottom: 7px;
}

.tableWrapper {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
