.loginMainDiv {
  background: #c5d8e1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 13%;
  height: auto;
  color: black;
}
.PwdMainDiv {
  background: #f7f7f7;
  min-height: 65vh;
}
.clogo {
  padding: 10px;
}
.clogo .companyLogo {
  width: 120px;
  height: 46px;
  object-fit: contain;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}
.row .label {
  font-weight: bold;
  display: flex;
  float: right;
  color: #223051;
  font-size: 18px;
}
.row .label .left {
  margin-right: 20px;
}
.row .label .right {
  font-family: "MyriadProSemiBold" !important;
}
.row .bgLoginImage {
  display: flex;
  flex-direction: column-reverse;
  background-image: linear-gradient(to top, #0a6fb8, #03416f, #0a6fb8);
}
.row .bgLoginImage label {
  font-family: "MyriadProSemiBold" !important;
  padding: 15% 15% 15% 5%;
  word-wrap: pre;
  font-size: 32px;
  font-weight: 600;
}
.row.row1 {
  padding: 0;
  box-shadow: 0 4px 4px 0 #9babb5;
}
.row.row2 {
  margin-top: 5px;
  padding: 0;
  box-shadow: 0 4px 4px 0 #9babb5;
}
.loginArea {
  padding: 30px;
  background: #fff;
}
.bgLoginImage,
.loginArea {
  height: calc(100vh - 100px);
}

.logoZone {
  position: absolute;
  top: 35%;
  height: 24%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #fff;
  width: 100%;
}
.pwdArea {
  padding: 20px;
  background: #fff;
}
.pwdArea {
  height: 499px;
}
.loginForm {
  padding: 5px 30px;
  line-height: 1;
  background: #fff;
}
.loginForm label {
  font-size: 14px;
  color: #7c869c;
  font-weight: normal;
}
.loginForm .mainLabelLogin {
  display: flex;
  color: #0a6fb8;
  flex-direction: row;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 10px 10px 0;
  box-sizing: border-box;
}
.loginForm .pwdLabelLogin {
  display: flex;
  justify-content: center;
  color: #223051;
  flex-direction: row;
  font-size: 24px;
  font-weight: bold;
  padding: 0px 10px 5px 10px;
  box-sizing: border-box;
}
.loginForm .lastLabel {
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.loginForm .lastLabel a {
  margin-left: 10px;
}
.loginForm .subSpan {
  display: block;
  justify-content: center;
  padding: 0 5px 30px 5px;
}
.loginForm .subSpan span {
  display: block;
  font-size: 16px;
  font-weight: normal;
  color: #231f20;
  text-align: center;
  padding-top: 6px;
}
.loginForm input {
  border-radius: 0;
  height: 40px;
}
.loginForm .loginFormForgot {
  float: right;
  color: #0a6fb8;
}
.loginForm .loginFormForgot:hover {
  color: #1890ff;
}
.loginForm .loginFormButton {
  width: 100%;
  /* border-radius: 30px; */
  height: 40px;
  border-color: #0a6fb8;
  background: #0a6fb8;
  margin: 20px 0;
  font-size: 16px;
}
.loginForm .loginFormButton:hover {
  border-color: #1f2a44;
  background: #1f2a44;
}
.changePwdForm {
  padding: 5px 10px;
  line-height: 1;
  background: #fff;
}
.changePwdForm label {
  font-size: 14px;
  color: #7c869c;
  font-weight: normal;
}
.changePwdForm .mainLabelLogin {
  display: flex;
  justify-content: center;
  color: #223051;
  flex-direction: row;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 10px 25px 10px;
  box-sizing: border-box;
}
.changePwdForm .pwdLabelLogin {
  display: flex;
  justify-content: center;
  color: #223051;
  flex-direction: row;
  font-size: 24px;
  font-weight: bold;
  padding: 0px 10px 5px 10px;
  box-sizing: border-box;
}
.changePwdForm .lastLabel {
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.changePwdForm .lastLabel a {
  margin-left: 10px;
}
.changePwdForm .subSpan {
  display: block;
  justify-content: center;
  padding: 0 5px 30px 5px;
}
.changePwdForm .subSpan span {
  display: block;
  font-size: 16px;
  font-weight: normal;
  color: #231f20;
  text-align: center;
  padding-top: 6px;
}
.changePwdForm input {
  border-radius: 0;
  margin-bottom: 10px;
  height: 40px;
}
.changePwdForm .loginFormForgot {
  float: right;
  color: #908d8d;
}
.changePwdForm .loginFormForgot:hover {
  color: #1890ff;
}
.changePwdForm .loginFormButton {
  width: 100%;
  border-radius: 30px;
  height: 40px;
  border-color: #1f2a44;
  background: #1f2a44;
  margin: 20px 0;
  font-size: 16px;
  margin: 0px;
}
.changePwdForm .loginFormButton:hover {
  border-color: #1f2a44;
  background: #1f2a44;
}

.loginForm label,
.loginForm span,
.loginForm a {
  font-family: "MyriadProSemiBold" !important;
  font-size: 14px;
}

.frgtPswdCss {
  width: 100%;
  padding: 30px 45px;
  height: 500px;
  background: linear-gradient(to right, #bdc3c7, #2c3e50);
}

.registerRow {
  padding: 15px 10px 10px 10px;
  font-weight: normal;
}

.register {
  color: #0a6fb8;
}
