@import "~antd/dist/antd.css";
/* @import "./styles/main.scss"; */

@font-face {
  font-family: "MyriadProRegular";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: local("MyriadProRegular"),
    url(./assets/fonts/MyriadProRegularFont.woff) format("woff");
}

@font-face {
  font-family: "MyriadProBold";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: local("MyriadProBold"),
    url(./assets/fonts/MyriadProBoldFont.woff) format("woff");
}

@font-face {
  font-family: "MyriadProSemiBold";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: local("MyriadProSemiBold"),
    url(./assets/fonts/MyriadProSemiBold.woff) format("woff");
}

@font-face {
  font-family: "MyriadProSemiBoldItalic";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: local("MyriadProSemiBoldItalic"),
    url(./assets/fonts/MyriadProSemiBoldItalic.woff) format("woff");
}

@font-face {
  font-family: "MyriadProLight";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: local("MyriadProLight"),
    url(./assets/fonts/MyriadProLight.woff) format("woff");
}

body {
  margin: 0;
  font-family: "MyriadProRegular", Helvetica, Georgia;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
section,
p,
a,
input,
option,
span,
label,
ul,
li,
table {
  font-family: "MyriadProRegular", Helvetica, Georgia !important;
}

.companyLogo {
  max-width: 200px;
}

.loader-login {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.centering {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.sCenter {
  display: flex;
  align-items: center;
}
