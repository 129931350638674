body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 100%;
  display: flex;
  align-self: center;
  margin: auto;
}
element {
  --font-stack: "Quicksand", sans-serif;
  --grey: #313232;
  --light-grey: #f7f8fc;
  --blue: #4562ec;
  --dark-blue: #2945a8;
  --green: #49e8c2;
}

body {
  color: #313232;
  background-color: #f7f8fc;
  font-family: "Quicksand", sans-serif;
}

.app-container {
  background-image: url(./assets/images/89780.jpg),
    url(./assets/images/89780.jpg);
  /* background-size: 100% 100%, cover; */
  background-color: #f7f7f7;
  min-height: calc(100vh - 42px);
  background: "transparent";
  margin-top: 55px;
  padding: 20px 16px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  background-attachment: fixed;
}
.ant-form-item-label > label {
  font-size: 14px;
  color: #293640 !important;
}

.login-footer {
  display: flex;
  justify-content: center;
}
.login-footer h4 {
  padding: 0 10px;
  color: #8692a6 !important;
}

.registerForm .ant-row .ant-col-12:first-child {
  padding-right: 10px;
}

.registerForm .ant-row .ant-col-12:last-child {
  padding-left: 10px;
}

.registerForm .ant-form-item-control .ant-select .ant-select-selector {
  height: 40px;
  align-items: center;
}
.registerForm .ant-form-item .ant-form-item-label {
  padding: 0;
}
.ant-layout-header {
  background: #fff !important;
}
.ant-layout-content {
  padding: 20px 20px;
}
.card-submenu .ant-card-body {
  padding: 10px;
}
.layout .card-submenu .ant-card-bordered {
  border: none;
  border-bottom: 1px solid #f0f0f0;
}
.anychart-credits {
  display: none !important;
}
