body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  margin: auto;
}
element {
  --font-stack: "Quicksand", sans-serif;
  --grey: #313232;
  --light-grey: #f7f8fc;
  --blue: #4562ec;
  --dark-blue: #2945a8;
  --green: #49e8c2;
}

body {
  color: #313232;
  background-color: #f7f8fc;
  font-family: "Quicksand", sans-serif;
}

.app-container {
  background-image: url(/static/media/89780.9bdf4797.jpg),
    url(/static/media/89780.9bdf4797.jpg);
  /* background-size: 100% 100%, cover; */
  background-color: #f7f7f7;
  min-height: calc(100vh - 42px);
  background: "transparent";
  margin-top: 55px;
  padding: 20px 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  background-attachment: fixed;
}
.ant-form-item-label > label {
  font-size: 14px;
  color: #293640 !important;
}

.login-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.login-footer h4 {
  padding: 0 10px;
  color: #8692a6 !important;
}

.registerForm .ant-row .ant-col-12:first-child {
  padding-right: 10px;
}

.registerForm .ant-row .ant-col-12:last-child {
  padding-left: 10px;
}

.registerForm .ant-form-item-control .ant-select .ant-select-selector {
  height: 40px;
  -webkit-align-items: center;
          align-items: center;
}
.registerForm .ant-form-item .ant-form-item-label {
  padding: 0;
}
.ant-layout-header {
  background: #fff !important;
}
.ant-layout-content {
  padding: 20px 20px;
}
.card-submenu .ant-card-body {
  padding: 10px;
}
.layout .card-submenu .ant-card-bordered {
  border: none;
  border-bottom: 1px solid #f0f0f0;
}
.anychart-credits {
  display: none !important;
}

/* @import "./styles/main.scss"; */

@font-face {
  font-family: "MyriadProRegular";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: local("MyriadProRegular"),
    url(/static/media/MyriadProRegularFont.d30b0546.woff) format("woff");
}

@font-face {
  font-family: "MyriadProBold";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: local("MyriadProBold"),
    url(/static/media/MyriadProBoldFont.1c643226.woff) format("woff");
}

@font-face {
  font-family: "MyriadProSemiBold";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: local("MyriadProSemiBold"),
    url(/static/media/MyriadProSemiBold.230dfc3b.woff) format("woff");
}

@font-face {
  font-family: "MyriadProSemiBoldItalic";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: local("MyriadProSemiBoldItalic"),
    url(/static/media/MyriadProSemiBoldItalic.6c2fc819.woff) format("woff");
}

@font-face {
  font-family: "MyriadProLight";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: local("MyriadProLight"),
    url(/static/media/MyriadProLight.43b72dcd.woff) format("woff");
}

body {
  margin: 0;
  font-family: "MyriadProRegular", Helvetica, Georgia;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
section,
p,
a,
input,
option,
span,
label,
ul,
li,
table {
  font-family: "MyriadProRegular", Helvetica, Georgia !important;
}

.companyLogo {
  max-width: 200px;
}

.loader-login {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}

.centering {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.sCenter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.auth_loginMainDiv__3oVhY {
  background: #c5d8e1;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px 13%;
  height: auto;
  color: black;
}
.auth_PwdMainDiv__V1IFM {
  background: #f7f7f7;
  min-height: 65vh;
}
.auth_clogo__3LtSz {
  padding: 10px;
}
.auth_clogo__3LtSz .auth_companyLogo__1HpW5 {
  width: 120px;
  height: 46px;
  object-fit: contain;
}
.auth_row__b_fQw {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
}
.auth_row__b_fQw .auth_label__2GOCx {
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  float: right;
  color: #223051;
  font-size: 18px;
}
.auth_row__b_fQw .auth_label__2GOCx .auth_left__15U3W {
  margin-right: 20px;
}
.auth_row__b_fQw .auth_label__2GOCx .auth_right__YyYBa {
  font-family: "MyriadProSemiBold" !important;
}
.auth_row__b_fQw .auth_bgLoginImage__1LE1n {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  background-image: linear-gradient(to top, #0a6fb8, #03416f, #0a6fb8);
}
.auth_row__b_fQw .auth_bgLoginImage__1LE1n label {
  font-family: "MyriadProSemiBold" !important;
  padding: 15% 15% 15% 5%;
  word-wrap: pre;
  font-size: 32px;
  font-weight: 600;
}
.auth_row__b_fQw.auth_row1__1V_AQ {
  padding: 0;
  box-shadow: 0 4px 4px 0 #9babb5;
}
.auth_row__b_fQw.auth_row2__3V7X_ {
  margin-top: 5px;
  padding: 0;
  box-shadow: 0 4px 4px 0 #9babb5;
}
.auth_loginArea__1o-jw {
  padding: 30px;
  background: #fff;
}
.auth_bgLoginImage__1LE1n,
.auth_loginArea__1o-jw {
  height: calc(100vh - 100px);
}

.auth_logoZone__14XLJ {
  position: absolute;
  top: 35%;
  height: 24%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  width: 100%;
}
.auth_pwdArea__15K-1 {
  padding: 20px;
  background: #fff;
}
.auth_pwdArea__15K-1 {
  height: 499px;
}
.auth_loginForm__3PN_7 {
  padding: 5px 30px;
  line-height: 1;
  background: #fff;
}
.auth_loginForm__3PN_7 label {
  font-size: 14px;
  color: #7c869c;
  font-weight: normal;
}
.auth_loginForm__3PN_7 .auth_mainLabelLogin__2h1QZ {
  display: -webkit-flex;
  display: flex;
  color: #0a6fb8;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 10px 10px 0;
  box-sizing: border-box;
}
.auth_loginForm__3PN_7 .auth_pwdLabelLogin__3Y2y6 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  color: #223051;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 24px;
  font-weight: bold;
  padding: 0px 10px 5px 10px;
  box-sizing: border-box;
}
.auth_loginForm__3PN_7 .auth_lastLabel__177uH {
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.auth_loginForm__3PN_7 .auth_lastLabel__177uH a {
  margin-left: 10px;
}
.auth_loginForm__3PN_7 .auth_subSpan__1Kdkw {
  display: block;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 5px 30px 5px;
}
.auth_loginForm__3PN_7 .auth_subSpan__1Kdkw span {
  display: block;
  font-size: 16px;
  font-weight: normal;
  color: #231f20;
  text-align: center;
  padding-top: 6px;
}
.auth_loginForm__3PN_7 input {
  border-radius: 0;
  height: 40px;
}
.auth_loginForm__3PN_7 .auth_loginFormForgot__3e7Vr {
  float: right;
  color: #0a6fb8;
}
.auth_loginForm__3PN_7 .auth_loginFormForgot__3e7Vr:hover {
  color: #1890ff;
}
.auth_loginForm__3PN_7 .auth_loginFormButton__1qGEW {
  width: 100%;
  /* border-radius: 30px; */
  height: 40px;
  border-color: #0a6fb8;
  background: #0a6fb8;
  margin: 20px 0;
  font-size: 16px;
}
.auth_loginForm__3PN_7 .auth_loginFormButton__1qGEW:hover {
  border-color: #1f2a44;
  background: #1f2a44;
}
.auth_changePwdForm__3Muyy {
  padding: 5px 10px;
  line-height: 1;
  background: #fff;
}
.auth_changePwdForm__3Muyy label {
  font-size: 14px;
  color: #7c869c;
  font-weight: normal;
}
.auth_changePwdForm__3Muyy .auth_mainLabelLogin__2h1QZ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  color: #223051;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 10px 25px 10px;
  box-sizing: border-box;
}
.auth_changePwdForm__3Muyy .auth_pwdLabelLogin__3Y2y6 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  color: #223051;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 24px;
  font-weight: bold;
  padding: 0px 10px 5px 10px;
  box-sizing: border-box;
}
.auth_changePwdForm__3Muyy .auth_lastLabel__177uH {
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.auth_changePwdForm__3Muyy .auth_lastLabel__177uH a {
  margin-left: 10px;
}
.auth_changePwdForm__3Muyy .auth_subSpan__1Kdkw {
  display: block;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 5px 30px 5px;
}
.auth_changePwdForm__3Muyy .auth_subSpan__1Kdkw span {
  display: block;
  font-size: 16px;
  font-weight: normal;
  color: #231f20;
  text-align: center;
  padding-top: 6px;
}
.auth_changePwdForm__3Muyy input {
  border-radius: 0;
  margin-bottom: 10px;
  height: 40px;
}
.auth_changePwdForm__3Muyy .auth_loginFormForgot__3e7Vr {
  float: right;
  color: #908d8d;
}
.auth_changePwdForm__3Muyy .auth_loginFormForgot__3e7Vr:hover {
  color: #1890ff;
}
.auth_changePwdForm__3Muyy .auth_loginFormButton__1qGEW {
  width: 100%;
  border-radius: 30px;
  height: 40px;
  border-color: #1f2a44;
  background: #1f2a44;
  margin: 20px 0;
  font-size: 16px;
  margin: 0px;
}
.auth_changePwdForm__3Muyy .auth_loginFormButton__1qGEW:hover {
  border-color: #1f2a44;
  background: #1f2a44;
}

.auth_loginForm__3PN_7 label,
.auth_loginForm__3PN_7 span,
.auth_loginForm__3PN_7 a {
  font-family: "MyriadProSemiBold" !important;
  font-size: 14px;
}

.auth_frgtPswdCss__H8yv3 {
  width: 100%;
  padding: 30px 45px;
  height: 500px;
  background: linear-gradient(to right, #bdc3c7, #2c3e50);
}

.auth_registerRow__KPZHL {
  padding: 15px 10px 10px 10px;
  font-weight: normal;
}

.auth_register__1GsHp {
  color: #0a6fb8;
}

.formName {
  width: auto;
  height: 31px;
  font-size: 24px;
  left: 52px;
  color: #2d2d2d;
  position: relative;
  top: 33px;
}

.mehlogin {
  background: url(/static/media/logo.aa929b8b.png) no-repeat;
  height: 100%;
}

.labeling {
  position: relative;
  font-size: 14px;
  color: #858585;
  top: 8px;
}

.form {
  width: 320px;
  height: 350px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.71);
  background-color: #ffffff;
  top: 362px;
  left: 804px;
  float: right;
  margin-right: 10%;
}

.formInput {
  position: relative;
  height: 45px;
  margin: 37px 52px;
  top: 30px;
}

.loginFormButton {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  background-color: #1890ff;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.submit {
  margin: 2px 52px;
  position: relative;
  top: 50px;
}

.footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.fpTitle,
.fpSubTitle {
  font-size: 20px;
  color: #313232;
  font-family: "MyriadProSemiBold" !important;
}

.fpSubTitle {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 16px;
}

.header_menuHeader__20VMx {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.header_leftMenu__zNmrJ .header_logoItem__e5D85 {
  background-color: transparent !important;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.site-layout-content .ant-tabs-nav {
  margin-bottom: 0;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.chart-class {
  padding: 10px 0;
}

#ac-chart-container {
  width: calc(100% - 75px) !important;
}
.vertical-slider {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 70px;
  padding-top: 5%;
  background-color: #0a6fb8;
}

.horizontal-slider {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  height: 70px;
  padding: 5px 20px;
  background-color: #0a6fb8;
}
.chart-div {
  border: 1px solid #e5e5e5;
  margin: 10px 0;
}
.chart-div .horizontal-slider .ant-slider-mark-text {
  min-width: 100px;
  font-weight: 500;
}
.table-numeric {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.table-numeric span:first-child {
  font-size: 18px;
  font-weight: 700;
}

.ant-table-container table > thead th {
  font-weight: 900;
  padding-top: 7px;
  padding-bottom: 7px;
}

.tableWrapper {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 50px;
  margin: 5px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-layout-background-bg {
  background-image: url(/static/media/backgroundpng.fa0373ed.svg), url(/static/media/backgroundimg.38011bdd.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
  background-color: #f7f7f7;
}

.site-layout header,
.site-layout .ant-menu:first-child,
.ant-menu:nth-child(2) {
  background: #fff;
}
.site-layout-content {
  height: calc(100vh - 185px);
}
.layout .ant-layout-footer {
  padding: 0 24px 5px 24px;
}

.footer_footer__1lZIc {
  /* position: fixed; */
  /* left: 0; */
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 42px;
  max-height: 42px;
  padding: 10px;
  color: #292b2c;
  /* border-top: 1px solid #cfcfcf; */
  background-color: #fff;
  bottom: 0;
}

.footer_footer__1lZIc ul {
  margin: 0;
  display: -webkit-flex;
  display: flex;
  list-style: none;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.footer_footer__1lZIc li {
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  padding: 0px 12px;
  color: #292b2c;
}

.footer_footer__1lZIc li a {
  color: #505658;
}

.footer_footer__1lZIc li a:hover {
  color: #1a73e8;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

